import './init.js';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import Env from 'lib/env/Env';
import App from 'base/App/App';
import ErrorHandler from 'v1/containers/Errors/ErrorHandler/ErrorHandler';
import store from 'store';
import * as Sentry from '@sentry/react';
import Routes from 'base/Routes/Routes';
import 'lib/mixpanel/setupMixpanel';
import 'styles/index.scss';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SocketQueryUpdater } from '~/v4/core/useSocketQueryUpdater';
import { queryClient } from '~/v4/migration/queryClientSingleton';
import { initMigrationCacheReconciler } from '~/v4/migration/cacheReconciler';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

if (Env.sentryDSN) {
  Sentry.init({
    dsn: Env.sentryDSN,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.4, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'http://localhost:6010',
      'https://*.atellio.com',
      /^\/api\//,
      /^https:\/\/[a-zA-Z0-9-]+\.atellio\.com$/
    ]
  });
}

window.sessionId =
  Date.now().toString(36) + Math.random().toString(36).substring(2);

ReactDOM.createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ErrorHandler>
        <BrowserRouter>
          <App>
            <SocketQueryUpdater sessionId={window.sessionId} />
            <Routes store={store} />
          </App>
        </BrowserRouter>
      </ErrorHandler>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

initMigrationCacheReconciler(); // for taking some data from other query cache to another query cache. todo remove once we have proper backend api
